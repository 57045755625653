import { cn } from '@bem-react/classname';
import React from 'react';
import SubTitle from '../../../landingnewcomponents/SubTitle/Landing';
import './advantages.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.price
 * @param {Array.<{contents: string, amount: number}>} props.items
 *
 * @returns
 */
const Advantages = ({ items = [] }) => {
    const advantagesClass = cn('Advantages');
    return <div className={advantagesClass()}>
        <SubTitle thin>All plans included</SubTitle>
        <ul className={advantagesClass('List')}>
            {items.map((item, idx) => {
                return <li key={idx} className={advantagesClass('Item')}>{item}</li>
            })}
        </ul>
    </div>
}

export default Advantages;
