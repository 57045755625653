import { cn } from '@bem-react/classname';
import React from 'react';
import { Horisontal, Vertical } from '../../../landingnewcomponents/Flow';
import './price-card.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {Array.<{price: string, terms: Array<string>, custom: boolean}>} props.prices
 * @param {string} props.action
 * @param {string} props.actionLink
 * @param {boolean} props.recommended
 * @param {boolean} props.current
 * @param {Array<string>} props.features
 *
 * @returns
 */
const PriceCard = ({ title, subtitle, prices, action, actionLink="#", features, footnote, recommended = false, current = false }) => {
    const priceCardClass = cn('PriceCard');

    return <div className={priceCardClass()}>
        <div className={priceCardClass('Title', { recommended })}>{title}</div>
        <div className={priceCardClass('SubTitle')}>{subtitle}</div>
        <div className={priceCardClass('Prices')}>
        {prices.map(({ price, terms }, idx) => {
            return (<Horisontal key={idx}>
                <div className={priceCardClass('Price')}>{price}</div>
                <div className={priceCardClass('PriceTerms')}>
                    <Vertical>
                        {terms.map((e, idx) => {
                            return <div key={idx}>{e}</div>;
                        })}
                    </Vertical>
                </div>
            </Horisontal>);
        })}
        </div>

        <a className={priceCardClass('Action', { current })} href={actionLink}>{action}</a>
        <ul className={priceCardClass('Features')}>
            {features.map((feature, idx) => {
                return <li key={idx} className={priceCardClass('Features-Item')}>{feature}</li>;
            })}
        </ul>
        {footnote && <div className={priceCardClass('Footnote')}>{footnote}</div>}
    </div>
}

export default PriceCard;
