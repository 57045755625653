import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Advantages from "../components/Pricing/Advantages";
import PriceList from "../components/Pricing/List";
import PowerupCard from "../components/Pricing/PowerupCard";
import PriceCard from "../components/Pricing/PriceCard";
import Section, { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import SubTitle from "../landingnewcomponents/SubTitle/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import ContactUsSection from "../sections/contact-us";
import FAQSection from "../sections/faq";
import "./../common/styles.scss";


const PricingPage = (props) => {

  const { formatMessage } = useIntl();

  return (
    <Layout location={props.location}>
      <Section color={SECTION_COLOR.DarkBlue}>
        <Breadcrumbs
          items={[{ title: "Viewst", url: "/" }, { title: "Pricing", url: "/pricing/" }]}
          inverted
        />
        <Title color={TITLE_COLORS.White}>
          <FormattedMessage id="pages.pricing.sections.main.title" defaultMessage="Upgrade your plan" />
        </Title>
        <SubTitle thin>
          <FormattedMessage
            id="pages.pricing.sections.main.subtitle"
            defaultMessage="Do more with teams, bulk generation and ad networks’ integrations" />
        </SubTitle>
        <Advantages items={[
          "Unlimited projects",
          "Unlimited resizes",
          "Design editor",
          "Free photo stock access",
          "Animator with presets",
          "Ad network optimization",
          "Variable content generation",
          "Bulk generation"
        ]} />

        <PriceList>
          {/* <PriceCard
                        title={formatMessage({ id: "pages.pricing.sections.priceCard.starter.title", defaultMessage: "Starter" })}
                        subtitle={formatMessage({ id: "pages.pricing.sections.priceCard.starter.subtitle", defaultMessage: "For individual smm managers, designers and marketers" })}
                        price="$0"
                        terms={["Free forever"]}
                        action={formatMessage({ id: "pages.pricing.sections.priceCard.starter.action", defaultMessage: "Current plan" })}
                        features={[
                            "100 html and image downloads",
                            "20 video and gif downloads",
                            "25 background removes (standart res)",
                            "1 brandbook",
                        ]}
                        current
                    /> */}
          <PriceCard
            title={formatMessage({ id: "pages.pricing.sections.priceCard.pro.title", defaultMessage: "Pro+" })}
            subtitle={formatMessage({
              id: "pages.pricing.sections.priceCard.pro.subtitle",
              defaultMessage: "For professional marketers, designers and smm managers"
            })}
            prices={[
              { price: "$15", terms: ["Per month", "Billed monthly"] },
              { price: "$13", terms: ["Per month", "Billed yearly"] }
            ]}
            action={formatMessage({ id: "pages.pricing.sections.priceCard.pro.action", defaultMessage: "Buy Pro+" })}
            actionLink="https://wizard.viewst.com"
            features={[
              "Unlimited html/image downloads",
              "30 video/gif downloads/mo",
              "50 background removes/mo",
              "5 brandbooks",
              "Team access with 1 team and 2 members",
              "Unlimited projects and resizes",
              "Animator with presets",
              "Variable content generation",
              "Figma and tiff import",
              "Ad network optimization"
            ]}
            recommended
          />
          <PriceCard
            title={formatMessage({ id: "pages.pricing.sections.priceCard.business.title", defaultMessage: "Business" })}
            subtitle={formatMessage({
              id: "pages.pricing.sections.priceCard.business.subtitle",
              defaultMessage: "for professional marketing teams to boost effective collaboration"
            })}
            prices={[
              { price: "$345", terms: ["Per 5 seats per month", "Billed monthly"] },
              { price: "$290", terms: ["Per month", "Billed yearly"] },
              { price: "$69", terms: ["for additional seat per month"] }
            ]}
            action={formatMessage({
              id: "pages.pricing.sections.priceCard.business.action",
              defaultMessage: "Buy Business"
            })}
            actionLink="https://wizard.viewst.com"
            features={[
              "Includes everything Pro+ has, plus:",
              "Unlimited html/image downloads",
              "500 video/gif downloads/mo*",
              "500 background removes/mo*",
              "Unlimited brandbooks",
              "Team access with unlimited teams and 5+ members",
              "Link preview and commenting",
              "Facebook ads manager integration",
              "Feed generation",
              "Priority support"
            ]}
            footnote="* For every additional seat adds 100 downloads and background removes"
            recommended
          />
          <PriceCard
            title={formatMessage({
              id: "pages.pricing.sections.priceCard.enterprise.title",
              defaultMessage: "Enterprise"
            })}
            subtitle={formatMessage({
              id: "pages.pricing.sections.priceCard.enterprise.subtitle",
              defaultMessage: "For professional marketers, designers and smm managers"
            })}
            prices={[{
              price: formatMessage({
                id: "pages.pricing.sections.priceCard.price.onDemand",
                defaultMessage: "On demand"
              }), terms: []
            }]}
            action={formatMessage({
              id: "pages.pricing.sections.priceCard.enterprise.action",
              defaultMessage: "Contact us"
            })}
            actionLink="https://viewst.com/contacts/"
            features={[
              "Includes everything Business has, plus:",
              "Custom downloads",
              "Custom background removes",
              "Custom team access",
              "Custom templates",
              "White label integration",
              "Customizing for your tasks",
              "Dedicated manager"
            ]}
          />
        </PriceList>
      </Section>
      <Section color={SECTION_COLOR.DarkBlue}>
        <Title color={TITLE_COLORS.White}><FormattedMessage id="pages.pricing.sections.powerUps.title"
                                                            defaultMessage="Power-ups" /></Title>
        <SubTitle thin><FormattedMessage id="pages.pricing.sections.powerUps.subtitle."
                                         defaultMessage="Buy now, use anytime" /></SubTitle>
        <PriceList>
          <PowerupCard
            items={[
              { amount: 50, contents: "MP4 & GIF downloads" },
              { amount: 50, contents: "Backgrounds removes" }
            ]}
            price="$19"
          />
          <PowerupCard
            items={[
              { amount: 250, contents: "MP4 & GIF downloads" },
              { amount: 150, contents: "Backgrounds removes" }
            ]}
            price="$49"
          />
          <PowerupCard
            items={[
              { amount: 1000, contents: "MP4 & GIF downloads" },
              { amount: 600, contents: "Backgrounds removes" }
            ]}
            price="$199"
          />
          <PowerupCard
            items={[
              { amount: 4000, contents: "MP4 & GIF downloads" },
              { amount: 4000, contents: "Backgrounds removes" }
            ]}
            price="$499"
          />

        </PriceList>
      </Section>
      <FAQSection />
      <ContactUsSection />
    </Layout>
  );
};

export default PricingPage;
