module.exports = [
  {
    "title": "Is there a Viewst app that can work on a mobile phone?",
    "content": "<p>Sadly, the Viewst mobile app is nonexistent. The reason behind this is a deliberate one aimed at better\n" +
      "      serviceability for users.</p><p>The Viewst website utilizes powerful servers and databases that generate tons of templates in a matter of\n" +
      "      seconds. This would be difficult to implement on a mobile phone and as such, the Viewst banner service is\n" +
      "      not available as an application.</p>" +
      "      <p>Viewst is however Ipad-compatible and you can access the Viewst banner building workspace directly from your\n" +
      "      Ipad device.</p>"
  }, {
  "title": "In what formats can I download files?",
    "content": "<p>You are able to save your created designs in various formats like the JPG and PNG\n" +
      "      formats for pictures, MP4 or GIF for animations and also HTML.</p>"
  }, {
  "title":"What could be done in Viewst", content: "<p>\n" +
      "      The Viewst platform was originally intended to design various social media banners. However, you can employ\n" +
      "      this wonderful platform to create any of the following:\n" +
      "      <ol>\n" +
      "        <li>- social media banners of any size</li>\n" +
      "        <li>- display ads banners</li>\n" +
      "        <li>- video banners</li>\n" +
      "        <li>- animated banners and many more...</li>\n" +
      "      </ol>\n" +
      "    </p><p class=\"Paragraph\">\n" +
      "            Not only limited to banners, you are also able to:\n" +
      "            <ol>\n" +
      "              <li>- access banner templates and other stock photo galleries</li>\n" +
      "              <li>- customize banners with the assistance of an online editor</li>\n" +
      "              <li>- erase backgrounds</li>\n" +
      "              <li>- upload custom fonts, Photoshop and Figma files</li>\n" +
      "              <li>- resize all templates or create custom sizes</li>\n" +
      "              <li>- collaborate with your team using the 'Team Access' feature</li>\n" +
      "            </ol>\n" +
      "          </p>"
  }, {
  "title": "Can I make animated banners?",
    "content": "<p class=\"Paragraph\">\n" +
      "      Yes, you can create animated Youtube banners in Viewst, and it's super easy. <a href=\"https://www.youtube.com/watch?v=Oi6qhWuez5M\" target='_blank'>Check how easy it is!</a>\n" +
      "    </p>"
  },
  {
    "title": "Can I make Youtube banners compatible with ad networks?",
    "content": "<p class=\"Paragraph\">\n" +
      "      Yes, you can easily <LandingLink\n" +
      "      to=\"https://www.youtube.com/watch?v=Oi6qhWuez5M\">create Youtube banners</LandingLink> compatible for ad\n" +
      "      networks. Here, it is this easy to create ad banners in Viewst. Some of our supported ad networks include:\n" +
      "      <ol>\n" +
      "        <li>- Google Display & Video 360</li>\n" +
      "        <li>- Google Manager</li>\n" +
      "        <li>- Choozle</li>\n" +
      "        <li>- Sizmek</li>\n" +
      "        <li>- Verizon</li>\n" +
      "        <li>- XANDR</li>\n" +
      "        <li>- Rambler</li>\n" +
      "        <li>- Smart AdServer</li>\n" +
      "        <li>- myTarget</li>\n" +
      "        <li>- Yandex.Direct</li>\n" +
      "        <li>- Getintent</li>\n" +
      "        <li>- ADFOX</li>\n" +
      "        <li>- Adriver</li>\n" +
      "        <li>- Ströer Media Solutions</li>\n" +
      "        <li>- Yandex Display</li>\n" +
      "        <li>- AdRoll</li>\n" +
      "        <li>- Delta Projects</li>\n" +
      "        <li>- Adcrowd</li>\n" +
      "        <li>- TRADE DESK</li>\n" +
      "        <li>- BidTheatre</li>\n" +
      "      </ol>\n" +
      "    </p>"
  },
  {
    "title": "When can I cancel my subscription?",
    "content": "<p class=\"Paragraph\">\n" +
      "      The short answer? Any time! You can unsubscribe from Viewst for free, and at any point in time.</p><p> <a\n" +
      "      href=\"https://www.youtube.com/watch?v=Oi6qhWuez5M\" target=\"_blank\">Here you can\n" +
      "      see</a> how to cancel your subscription.\n" +
      "    </p>"
  },
  {
    "title": "Can I upload other files?",
    "content":"<p class=\"Paragraph\">Yes, Viewst provides you with the option of uploading your personal fonts and brand\n" +
      "      images. It also supports Photoshop and Figma files.</p>"
  }
];