import { cn } from '@bem-react/classname';
import React, { useState } from 'react';
import './toggle-list.scss';

const toggleList = cn('ToggleList');
const ToggleList = ({children}) => {
    return <ul className={toggleList()}>{children}</ul>
}

const ToggleItem = ({title, children}) => {
    const [open, setOpen] = useState(false);

    return <li className={toggleList('ToggleItem', {open})} >
        <div className={toggleList('ToggleItem-Title')} onClick={() => setOpen(!open)}>{title}</div>
        <div className={toggleList('ToggleItem-Content', {open})}>{children}</div>
    </li>
}

export { ToggleList, ToggleItem };
