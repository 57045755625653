import { cn } from '@bem-react/classname';
import React from 'react';
import './price-list.scss';

const PriceList = ({children}) => {
    const priceListClass = cn('PriceList');
    return <div className={priceListClass()}>
        {children}
    </div>
}

export default PriceList;
