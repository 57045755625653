import React from "react";
import Section, { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import { ToggleItem, ToggleList } from "../landingnewcomponents/ToggleList/Landing";
import faqData from "../data/faq";
import "./faq.scss";

const FAQSection = ({ faq = faqData }) => {
  return (
    <Section color={SECTION_COLOR.White}>
      <Title color={TITLE_COLORS.DarkBlue}>FAQ</Title>
      <ToggleList>
        {faq.map((faqItem) => {
          return (<ToggleItem key={faqItem.title} title={faqItem.title}>
            <div dangerouslySetInnerHTML={{ __html: faqItem.content }}></div>
          </ToggleItem>);
        })}
      </ToggleList>
    </Section>
  );
};

export default FAQSection;
