import { cn } from '@bem-react/classname';
import React from 'react';
import { Horisontal } from '../../../landingnewcomponents/Flow';
import './powerup-card.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.price
 * @param {Array.<{contents: string, amount: number}>} props.items
 *
 * @returns
 */
const PowerupCard = ({ items=[], price}) => {
    const powerupCardClass = cn('PowerupCard');
    return <div className={powerupCardClass()}>
        {items.map((item, idx) => {
            return <Horisontal key={idx}>
                <div className={powerupCardClass('Amount')}>{item?.amount}</div>
                <div className={powerupCardClass('Contents')}>{item?.contents}</div>
            </Horisontal>
        })}

        <Horisontal centerred spaced>
            <div className={powerupCardClass('Price')}>{price}</div>
            <div className={powerupCardClass('Action')}>Buy</div>
        </Horisontal>
    </div>
}

export default PowerupCard;
